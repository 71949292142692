<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: PondsCountCard.vue
Description:This file Shows the Information above total active inactive ponds
-->
<template>
  <er-card
    class="ponds-count-card"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-row
      slot="header"
      type="flex"
      justify="start"
      class="pond-card-count__header"
      v-show="!loading"
    >
      <img class="card-icon" src="@/assets/dashboard/pondlogo.svg" />
      <span
        class="el-col el-col-18 pond-card-count__header_content card-title"
        >{{ $t("Comn_ponds") }}</span
      >
    </el-row>

    <el-row
      type="flex"
      class="count-container"
      justify="start"
      v-show="!loading"
    >
      <el-col :span="18" class="radial__progress__container">
        <div>
          <radial-progress-bar
            :diameter="getRespDimentions.diameter"
            :completed-steps="getActivePonds"
            :total-steps="getPonds.length"
            innerStrokeColor="#ECF3F9"
            :inner-stroke-width="getRespDimentions.innerStrokewidth"
            :strokeWidth="getRespDimentions.innerWidth"
            :startColor="'#5da4e1'"
            :stopColor="'#37e06c'"
          >
            <div class="radial__progress__inner__container">
              <p>{{ getActivePercent }}</p>
              <p>{{ $t("Comn_active") }}</p>
            </div>
          </radial-progress-bar>
        </div>
      </el-col>
      <el-col :span="4" class="count-stats" v-show="!loading">
        <div class="count" @click="hyperLinkPonds('ALL')">
          <p>{{ getPonds.length }}</p>
          <p style="color: #508ff4">{{ $t("Comn_total") }}</p>
        </div>

        <div class="count" @click="hyperLinkPonds('ACTIVE')">
          <p>{{ getActivePonds }}</p>
          <p style="color: #31d8ac">{{ $t("Comn_active") }}</p>
        </div>

        <div class="count" @click="hyperLinkPonds('INACTIVE')">
          <p>{{ getInActivePonds }}</p>
          <p style="color: #ff5c83">{{ $t("Comn_inactive") }}</p>
        </div>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RadialProgressBar from "vue-radial-progress";
import redirectsMixin from "@/mixins/redirectsMixin";
export default {
  name: "PondsCountCard",
  props: ["isLoading"],
  mixins: [redirectsMixin],
  components: {
    RadialProgressBar
  },
  data: function () {
    return {
      query: {
        location_id: null
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    ...mapGetters("dashboard", {
      getDashboardPonds: "getDashboardPonds"
    }),
    loading() {
      return this.isLoading;
    },
    getRespDimentions() {
      const dimentions = {
        diameter: 200,
        innerStrokewidth: 40,
        innerWidth: 26
      };

      if (window.innerWidth <= 2560 && window.innerWidth > 1920) {
        dimentions.diameter = 290;
        dimentions.innerStrokewidth = 40;
        dimentions.innerWidth = 30;
      } else if (window.innerWidth <= 1920 && window.innerWidth > 1660) {
        dimentions.diameter = 290;
        dimentions.innerStrokewidth = 40;
        dimentions.innerWidth = 30;
      } else if (window.innerWidth <= 1660 && window.innerWidth > 1280) {
        dimentions.diameter = 230;
        dimentions.innerStrokewidth = 40;
        dimentions.innerWidth = 30;
      } else if (window.innerWidth <= 1280) {
        dimentions.diameter = 200;
        dimentions.innerStrokewidth = 40;
        dimentions.innerWidth = 30;
      } else {
        dimentions.diameter = 400;
        dimentions.innerStrokewidth = 45;
        dimentions.innerWidth = 35;
      }

      return dimentions;
    },
    getActivePonds() {
      if (!this.getPonds) {
        return [];
      }
      return this.getPonds.filter(({ status }) => status === "ACTIVE").length;
    },
    getInActivePonds() {
      if (!this.getPonds) {
        return [];
      }
      return this.getPonds.filter(({ status }) => status === "INACTIVE").length;
    },
    getActivePercent() {
      if (!this.getPonds) {
        return 0;
      }
      const per = Math.round(
        (this.getActivePonds * 100) / this.getPonds.length
      );
      if (!per && per !== 0) {
        return "-";
      }
      return `${per}%`;
    },
    getPonds() {
      return this.getDashboardPonds ? this.getDashboardPonds : [];
    }
  },
  async mounted() {
    // await this.initComponent();
  },
  methods: {
    ...mapActions("dashboard", {
      fetchAllPonds: "fetchAllPonds",
      pondCountCardHyperLink: "pondCountCardHyperLink"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async hyperLinkPonds(input) {
      this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
      await this.pondCountCardHyperLink({ value: input });
      this.rdm__navigateToPathWithLocationId({
        path: "/user/dashboard",
        query: {
          tab: "pondInfoTab"
        }
      });
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size($offset_value: 0) {
  @include responsiveProperty(
    font-size,
    $app_font_size_1 + $offset_value,
    $app_font_size_2 + $offset_value,
    $app_font_size_3 + $offset_value
  );
}

.ponds-count-card {
  .pond-card-count__header {
    display: flex;
    align-items: center;
    @include responsiveProperty(line-height, 1.8, 1.8, 1.8);
  }
  .el-card__body {
    height: 70% !important;
    // display: flex !important;
    /* align-content: center; */
    // align-items: center !important;
  }

  .count-container {
    display: flex;
    align-items: center;
    // width: 100%;

    .radial__progress__container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      // padding-right: 15px;
      margin: auto;
      // @media only screen and (max-width: 800px) and (min-width: 600px) {
      //   .radial__progress__container {
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;

      //     margin: auto;
      //   }
      // }
    }
    .radial__progress__inner__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .count {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      p:nth-of-type(1) {
        @include component-font-size(0);
      }
      p:nth-of-type(2) {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
    }
    .pg-container {
      div {
        text-align: center;
      }
      p:nth-of-type(1) {
        color: #0e6ba8;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
      p:nth-of-type(2) {
        @include component-font-size();
      }
    }
    .count-stats {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
    }
  }
  // .row-container {
  //   @include responsiveProperty(flex-direction, row, row, column);
  // }
}
</style>
