<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AverageWeeklyGrowth.vue
Description:this file gives the data averge weekly growth of the ponds for last 4 weeks
-->
<template>
  <er-card
    class="weight-growth-container"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <el-row
      v-show="!loading"
      slot="header"
      type="flex"
      justify="start"
      class="weight__growth__header"
    >
      <span class="el-col el-col-13 card-container">
        <img class="card__icon" src="@/assets/dashboard/weightGrowthIcon.svg" />
        <span class="card__header">{{ $t("average_weekly_growth") }}</span>
      </span>
      <el-col :span="11">
        <el-row class="weight-growth-stat">
          <el-row
            :class="[
              'weight__growth__container',
              currentWeek === index ? 'is-selected' : '',
            ]"
            v-for="(eachWeek, index) in getAvgWeeklyGrowth"
            :key="index"
            type="flex"
            @click.native="currentWeek = index"
          >
            <el-col
              tag="span"
              class="weight__growth__value"
              :data-units="$tc(getPreferredUnits.abw_and_growth_units, 2)"
            >
              {{ ftm__formatEmpty(ftm__digitPrecision(eachWeek, 2)) }}
            </el-col>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
    <el-row v-show="!loading">
      <div class="header">
        <span>{{ $t("Comn_range") }}</span>
        <span>{{ $t("Comn_ponds") }}</span>
      </div>
      <hr />
      <template v-if="getWeightGrowth.length > 0">
        <GrowthRangeitem :range="getPondsWithABWRangeItem[currentWeek]">
          <template slot="range__value">
            <p class="normal-text">
              {{ `${$t("without")} ${$t("ABW_abw")}` }}
            </p>
          </template>
        </GrowthRangeitem>
        <GrowthRangeitem
          v-for="item in getWeightGrowth"
          :key="item.name"
          :range="item"
        />
      </template>
      <el-row type="flex" justify="center" style="padding-top: 10px" v-else>
        <el-tag>{{ $t("Comn_no_data") }}</el-tag>
      </el-row>
    </el-row>
  </er-card>
</template>

<script>
import { mapGetters } from "vuex";
import GrowthRangeitem from "../../dashboard/components/GrowthRangeItem";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  name: "WeightGrowthCard",
  mixins: [filtersMixin],
  components: {
    GrowthRangeitem
  },
  props: ["isLoading"],
  data: function () {
    return {
      weightGrowthRange: [],
      query: {
        location_id: null
      },
      queryNweeks: {
        location_id: null,
        number_of_weeks: 4
      },
      rangeObject: {
        range1: 0,
        rangeType: "LESS_THAN_OR_EQUALS",
        range2: "",
        name: "Ponds with out ABW",
        total_ponds: 0,
        color: "#909399",
        percent: 0
      },
      currentWeek: 3,
      pondIds: []
    };
  },
  filters: {
    numberWithNDigitPrecision(value = 0, precision = 2) {
      const stockStr = value.toFixed(2);
      const dec = stockStr.split(".")[1];
      if (parseInt(dec) > 0) {
        return stockStr;
      } else {
        return stockStr.split(".")[0];
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("dashboard", {
      getStoreWeightGrowth: "getWeightGrowth",
      getStoreGrowthofNWeeks: "getweightGrowthOfNWeeks"
    }),
    getAvgWeeklyGrowth() {
      return (this.getStoreGrowthofNWeeks || []).map((x) =>
        x.total_growth > 0 ? x.total_growth / x.ponds_with_abw : 0
      );
    },
    getPondsWithABWRangeItem() {
      const nWeekGrowth = this.getStoreGrowthofNWeeks;
      if (!nWeekGrowth || !nWeekGrowth.length) {
        return Array(4).fill(this.rangeObject);
      }
      return nWeekGrowth.map((x) => ({
        range1: "No ABW",
        range2: "",
        name: "Ponds with out ABW",
        rangeType: "LESS_THAN_OR_EQUALS",
        total_ponds: x.ponds_without_abw,
        color: "#909399",
        percent: x.total_ponds
          ? (x.ponds_without_abw * 100) / x.total_ponds
          : 0,
        pondIds: this.getStoreGrowthofNWeeks[this.currentWeek]
      }));
    },
    getNoOfWeeks() {
      return this.getAvgWeeklyGrowth.length;
    },
    getWeightGrowth() {
      if (!this.getStoreGrowthofNWeeks) {
        return [];
      }
      const colors = ["#F24654", "#FE9B3D", "#FFCC2F", "#32DB28", "#35A22E"];
      const weekObj = this.getStoreGrowthofNWeeks[this.currentWeek];
      if (!weekObj) {
        return [];
      }
      const keys = ["<=0", ">0-1", ">1-1.5", ">1.5-2", ">2"];
      const weekAvgGrowth = weekObj.growth_ranges;
      const total = weekObj.total_ponds;
      return keys.map((key, index) => {
        const item = weekAvgGrowth[key];
        const percent = (item.total_ponds * 100) / total;
        const color = colors[index];
        item.color = color;
        item.percent = percent;
        item.pondIds = this.getStoreGrowthofNWeeks[this.currentWeek].ponds;
        return item;
      });
    },
    loading() {
      return this.isLoading;
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}

.weight-growth-container {
  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  &.er-card {
    .weight__growth__header {
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsiveProperty(padding-bottom, 1px, 0px, 0px);

      .card-container {
        // @include responsiveProperty(padding-bottom, 0px, 1px, 0px);
        display: flex;

        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_3
        );

        .card__icon {
          display: flex;
          margin-right: 10px;
        }
        .card__header {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .pond-card-count__header__total {
    text-transform: capitalize;
  }

  .wholeiconalign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .weight-growth-stat {
    // background: #233a73;
    // color: white;
    // display: flex;
    // color: #000000;

    display: flex;
    justify-content: flex-end;

    // @include responsiveProperty(padding, 5.5px, 7px, 8px);
    font-weight: 200;
    border-radius: 5px;

    .weight__growth__container {
      cursor: pointer;
      @include component-font-size;
      color: #0078ff;
      background-color: #d6eaff;
      &.is-selected {
        color: #ffffff;
        background-color: #233a73;
        .weight__growth__value {
          &:after {
            color: #5df953;
          }
        }
      }
      margin: auto;
      padding: 4px;
      padding: -1px;
      border-radius: 5px;

      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      text-transform: lowercase;
    }
    .weight__growth__value {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      flex-direction: column;
      letter-spacing: initial;
      @include responsiveProperty(min-width, 20px, 25px, 30px);
      @include responsiveProperty(max-width, 20px, 25px, 30px);

      &:after {
        content: attr(data-units);
        @include responsiveProperty(
          font-size,
          $app_font_size_v_small,
          $app_font_size_small,
          $app_font_size_1
        );
        margin-left: 5px;
        text-transform: lowercase;
        color: #0f9807;
        line-height: 1;
        margin-left: auto;
      }
    }
    .weight__growth__units {
      color: #5df953;
      display: block;
      text-align: end;
      letter-spacing: initial;
      @include responsiveProperty(
        font-size,
        $app_font_size_v_small,
        $app_font_size_v_medium,
        $app_font_size_small
      );
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    letter-spacing: 1px;
    line-height: 2;
    color: #0a2463;
    font-weight: 600;
  }
  hr {
    background: #6c7b8a;
    mix-blend-mode: normal;
    opacity: 0.08;
  }
}
</style>
