/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for the charts in the dashboard tab at customer end for pondlogs.
*/
import { baseChartConfig, xAxisConfig } from "@/config/baseChartConfig";
import i18n from "@/config/i18n";
import dateUtils from "@/utils/dateUtils";
import { digitPrecision } from "@/utils/filterUtils";
const feedData = [
  // first stack
  {
    name: "serie1",
    data: [],
    stack: 0
  },
  {
    name: "serie2",
    data: [],
    stack: 0
  }
];

const chartMeasurements = {
  spacingBottom: 10,
  marginBottom: 35,
  spacingTop: 1,
  spacingLeft: 1,
  spacingRight: 1
};

const feedConsumptionChartOptions = JSON.parse(JSON.stringify(baseChartConfig));

feedConsumptionChartOptions.tooltip = {
  formatter: function() {
    const tooltipGenerator = function(point) {
      if (point.options.dispense_feed_percentage) {
        const feed = point.options;
        return `<div>
     <span> ${feed.dateFormat}</span>
      <br/>
      <span>
      <span style="color: ${feed.color}" >
      ${
        feed.feedTrans
      }  </span> :<span style="font-weight :700">${digitPrecision(
          feed.y
        )}</span>/<span style="font-weight :700">${digitPrecision(
          feed.feed_value
        )}(${feed.units})</span>
        </span>
 

<br/>
${feed.dispensedFeedTrans}:${feed.dispense_feed_percentage}%
      </div>`;
      }
    };
    return this.points
      .map(eachPoint => {
        return tooltipGenerator(eachPoint.point);
      })
      .join("");
  }
};
feedConsumptionChartOptions.chart = {
  ...chartMeasurements,
  ...{ height: "60%" },
  renderTo: "container",
  type: "column",
  time: {
    useUTC: false
  }
};

feedConsumptionChartOptions.xAxis = xAxisConfig({
  type: "datetime",
  labels: {
    formatter: function() {
      return dateUtils.formatTZ(
        dateUtils.utcToZonedTime(this.value, "UTC"),
        "d/M",
        {
          timeZone: "UTC",
          locale: dateUtils.localesMap[i18n.locale]
        }
      );
    }
  },
  tickWidth: 1,
  tickLength: 5,
  tickmarkPlacement: "on"
});

feedConsumptionChartOptions.yAxis = {
  ...feedConsumptionChartOptions.yAxis,
  gridLineWidth: 0,
  tickAmount: 6,
  // tickInterval: 1500,
  opposite: false
  // min: 0,
  // max: 100,
};

feedConsumptionChartOptions.scrollbar = {
  enabled: false
};

feedConsumptionChartOptions.plotOptions = {
  column: {
    stacking: "normal"
  }
};

const feedConsumptionFeedAreaChartOptions = JSON.parse(
  JSON.stringify(baseChartConfig)
);

feedConsumptionFeedAreaChartOptions.tooltip = {
  formatter: function() {
    const tooltipGenerator = function(point) {
      const feed = point.options;
      return `<div>
     <span> ${feed.dateFormat}</span>
      <br/>
      <span>
      <span style="color: ${feed.color}" >
      <span style="font-weight :700">${feed.label +
        ":" +
        digitPrecision(feed.y)}</span>
        </span>`;
    };
    return this.points
      .map(eachPoint => {
        return tooltipGenerator(eachPoint.point);
      })
      .join("");
  }
};
feedConsumptionFeedAreaChartOptions.chart = {
  ...chartMeasurements,
  ...{ height: "60%" },
  renderTo: "container",
  type: "column",
  time: {
    useUTC: false
  }
};

feedConsumptionFeedAreaChartOptions.xAxis = xAxisConfig({
  type: "datetime",
  labels: {
    formatter: function() {
      return dateUtils.formatTZ(
        dateUtils.utcToZonedTime(this.value, "UTC"),
        "d/M",
        {
          timeZone: "UTC",
          locale: dateUtils.localesMap[i18n.locale]
        }
      );
    }
  },
  tickWidth: 1,
  tickLength: 5,
  tickmarkPlacement: "on"
});

feedConsumptionFeedAreaChartOptions.yAxis = {
  ...feedConsumptionChartOptions.yAxis,
  gridLineWidth: 0,
  tickAmount: 6,
  // tickInterval: 1500,
  opposite: false
  // min: 0,
  // max: 100,
};

feedConsumptionFeedAreaChartOptions.scrollbar = {
  enabled: false
};

feedConsumptionFeedAreaChartOptions.plotOptions = {
  column: {
    stacking: "normal"
  }
};

export const feedConsumptionConfig = {
  chart_options: feedConsumptionChartOptions,
  chart_options_feed_per_area: feedConsumptionFeedAreaChartOptions,
  series_generators: {
    feed_consumption: feedData
  }
};

const abwBiomassOptions = JSON.parse(JSON.stringify(baseChartConfig));

abwBiomassOptions.chart = {
  ...chartMeasurements,
  ...{ height: "75%" },
  type: "bar"
};

abwBiomassOptions.title = {
  text: undefined
};

abwBiomassOptions.scrollbar = {
  enabled: false
};

abwBiomassOptions.xAxis = xAxisConfig({
  categories: ["<=5", ">5-10", ">10-20", ">20-30", ">30"],
  title: {
    text: undefined
  }
});
abwBiomassOptions.yAxis = {
  min: 0,
  title: {
    // text: "Population (millions)",
    text: undefined
  },
  labels: {
    overflow: "justify"
  }
};

// abwBiomassOptions.tooltip = {
//   valueSuffix: " millions",
// };

abwBiomassOptions.plotOptions = {
  bar: {
    dataLabels: {
      enabled: true
    }
  }
};

abwBiomassOptions.legend = {
  enabled: false
};

abwBiomassOptions.credits = {
  enabled: false
};

// abwBiomassOptions.series = [
//   {
//     name: "Year 1800",
//     data: [107, 31, 635, 203, 2],
//   },
// ];

export const abwBiomassConfig = {
  chart_options: abwBiomassOptions
};
