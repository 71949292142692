<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dashboardTab.vue
Description:this file contains the info about all the cards in the Dashboard
-->
<template>
  <el-row class="dashboard-tab" slot="layout-pondlogs-scrollable-main">
    <el-row type="flex" :gutter="10" class="overall-dashboard-row">
      <el-col :span="6">
        <PondsCountCard :isLoading="loading['PondsCountCard']" />
      </el-col>
      <el-col :span="9">
        <FeedConsumption :isLoading="loading['FeedConsumption']" />
      </el-col>
      <el-col :span="9">
        <ABWBiomassCard :isLoading="loading['ABWBiomassCard']" />
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="10" class="overall-dashboard-row">
      <el-col :span="6" class="do-temp-stats">
        <el-row>
          <TemperatureCard :isLoading="loading['TemperatureCard']" />
        </el-row>
        <el-row>
          <DissolvedOxygenCard :isLoading="loading['DissolvedOxygenCard']" />
        </el-row>
      </el-col>
      <el-col :span="7">
        <AverageWeeklyGrowth :isLoading="loading['AverageWeeklyGrowth']" />
      </el-col>
      <el-col :span="11">
        <PondPerformanceCard :isLoading="loading['PondPerformanceCard']" />
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import PondsCountCard from "./components/PondsCountCard";
import FeedConsumption from "./components/FeedConsumptionCard";
import ABWBiomassCard from "./components/ABWBiomassCard";
import PondPerformanceCard from "./components/PondPerformanceCard";
import AverageWeeklyGrowth from "./components/AverageWeeklyGrowth";
import TemperatureCard from "./components/TemperatureCard";
import DissolvedOxygenCard from "./components/DissolvedOxygenCard";
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PondDashboard",
  mixins: [filtersMixin, errorHandlerMixin],
  components: {
    PondsCountCard,
    FeedConsumption,
    ABWBiomassCard,
    PondPerformanceCard,
    TemperatureCard,
    DissolvedOxygenCard,
    AverageWeeklyGrowth
  },
  data: function () {
    return {
      loading: {
        PondsCountCard: false,
        FeedConsumption: false,
        ABWBiomassCard: false,
        PondPerformanceCard: false,
        AverageWeeklyGrowth: false,
        TemperatureCard: false,
        DissolvedOxygenCard: false
      },
      query: {
        location_id: null
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    })
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("dashboard", {
      fetchPondPerformance: "fetchPondPerformance",
      fetchAverageWeightGrowthLastNWeeks: "fetchAverageWeightGrowthLastNWeeks",
      fetchDOSummary: "fetchDOSummary",
      fetchTempSummary: "fetchTempSummary",
      fetchAbwAndBiomass: "fetchAbwAndBiomass",
      fetchFeedConsumptionData: "fetchFeedConsumptionData",
      fetchAllPonds: "fetchAllPonds"
    }),
    async initComponent() {
      this.query.location_id =
        this.getCurrUserLocation && this.getCurrUserLocation._id;
      const dashboardStoreActions = {
        PondsCountCard: this.fetchAllPonds,
        FeedConsumption: this.fetchFeedConsumptionData,
        ABWBiomassCard: this.fetchAbwAndBiomass,
        PondPerformanceCard: this.fetchPondPerformance,
        AverageWeeklyGrowth: this.fetchAverageWeightGrowthLastNWeeks,
        TemperatureCard: this.fetchTempSummary,
        DissolvedOxygenCard: this.fetchDOSummary
      };
      const dashboardActionsQueryParamsObj = {
        PondsCountCard: {
          ...this.query,
          status: ["ACTIVE", "INACTIVE"],
          include: ["status"],
          get_all: true,
          not_include_test_ponds: true
        },
        FeedConsumption: {
          ...this.query
        },
        ABWBiomassCard: { ...this.query },
        PondPerformanceCard: { ...this.query },
        AverageWeeklyGrowth: { ...this.query },
        TemperatureCard: { ...this.query },
        DissolvedOxygenCard: { ...this.query }
      };
      try {
        await Promise.all(
          Object.keys(dashboardStoreActions).map((key) => {
            this.loading[key] = true;
            const promise = (
              dashboardStoreActions[key](dashboardActionsQueryParamsObj[key]) ||
              Promise.resolve()
            ).finally(() => {
              this.loading[key] = false;
            });
            return promise;
          })
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../styles/mixins.scss";
.dashboard-tab {
  .overall-dashboard-row {
    &:first-child {
      margin-bottom: 10px;
      .el-card__header {
        line-height: 1.4;
      }
    }
    .el-card {
      // @include responsiveProperty(height, 44vh, 45vh, 46vh);
      height: 100%;
    }
  }
  .do-temp-stats {
    display: flex;
    flex-direction: column;
    > .el-row {
      height: calc(50% - 5px);
      margin-bottom: 5px;
      margin-top: 5px;
    }
    > .el-row:first-child {
      margin-top: 0px;
    }
    > .el-row:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
