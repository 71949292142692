/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: baseChartConfig.js
Description: This file contains the default highcharts configuration useful all over the application
*/
// NOTE: Each dark and light variations of color are next to each other.So, please add colors at the end of the array.
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import dateUtils from "@/utils/dateUtils";
import i18n from "@/config/i18n";
const chartSeriesColors = [
  "#233a73",
  "#738ed4",
  "#f54e5e",
  "#f67985",
  "#33a02c",
  "#94e08f",
  "#5dcfe9",
  "#81daee",
  "#f5b800",
  "#ffd65c"
];

const noDataStyle = {
  style: {
    fontWeight: "bold",
    fontSize: "15px",
    color: "#303030"
  },
  useHTML: true
};
const legend = function(enabled) {
  return {
    enabled: enabled
  };
};
const defaultXAxisConfig = () => ({
  title: { text: "", align: "middle" },
  tickLength: 5,
  type: "datetime",
  events: {
    afterSetExtremes: () => {}
  },
  showEmpty: false,
  labels: {
    formatter: function() {
      if (this.axis.categories) {
        return this.axis.defaultLabelFormatter.call(this);
      }
      const startOfDay = dateUtils.startOfDay(
        dateUtils.utcToZonedTime(this.value, "UTC")
      );
      const value = dateUtils.utcToZonedTime(this.value, "UTC");
      if (
        this.isFirst === this.isLast &&
        this.dateTimeLabelFormat === "%H:%M:%S.%L"
      ) {
        return dateUtils.formatTZ(
          dateUtils.utcToZonedTime(this.value, "UTC"),
          "d MMM,yy",
          {
            timeZone: "UTC",
            locale: dateUtils.localesMap[i18n.locale]
          }
        );
      }
      if (this.tickPositionInfo) {
        switch (this.tickPositionInfo.unitName) {
          case "hour":
            if (dateUtils.isEqual(value, startOfDay)) {
              return dateUtils.formatTZ(
                dateUtils.utcToZonedTime(this.value, "UTC"),
                "d. MMM",
                {
                  timeZone: "UTC",
                  locale: dateUtils.localesMap[i18n.locale]
                }
              );
            }
            break;
        }
      }
      return this.axis.defaultLabelFormatter.call(this);
    }
  }
});

export const xAxisConfig = userOptions => {
  if (Array.isArray(userOptions)) {
    return userOptions.map(x => ({
      ...defaultXAxisConfig(),
      ...userOptions
    }));
  }
  return { ...defaultXAxisConfig(), ...userOptions };
};

export const baseChartConfig = {
  chart: {
    styleMode: true,
    resetZoomButton: {
      theme: {
        zIndex: 2000
      }
    },
    zoomType: "x"
  },
  colors: chartSeriesColors,
  legend: legend(false),
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  noData: noDataStyle,
  plotOptions: {
    series: {
      pointInterval: 24 * 3600 * 1000,
      turboThreshold: 10000000,
      dataGrouping: { enabled: false }
    }
  },
  rangeSelector: {
    enabled: false
  },
  scrollbar: {
    enabled: true,
    height: 10,
    showFull: false
  },
  series: [],
  tooltip: {
    useHTML: true,
    footerFormat: "</table>",
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    shared: true,
    split: false,
    valueDecimals: 2,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  yAxis: {
    opposite: false,
    title: {
      text: "",
      align: "middle",
      textAlign: "center",
      style: {
        color: "#000000"
      }
    },
    showLastLabel: true,
    showEmpty: false
  },
  xAxis: xAxisConfig()
};

export const getColorWithIndex = function(index) {
  return chartSeriesColors[index];
};
export default { baseChartConfig, getColorWithIndex, xAxisConfig };
